import { precacheAndRoute } from 'workbox-precaching/precacheAndRoute';
import { registerRoute } from 'workbox-routing';
import { StaleWhileRevalidate } from 'workbox-strategies';
import { cacheNames } from 'workbox-core';

import { PoiDocument } from './typescript/firestore';

import config from './scripts/config';

if (['production', 'staging'].includes(config.nodeEnv)) {
  // @ts-ignore
  precacheAndRoute(self.__WB_MANIFEST, {
    urlManipulation: ({ url }: { url: URL }) => {
      // Remove search params from precache urls
      return [{ ...url, href: url.href.split('?')[0], search: '' }];
    }
  });
}

let latestTimestamp = 0;

/**
 * Updates latest poi timestamp
 *
 * @param response Pois reponse
 */
async function updateLatestTimestamp(response: Response) {
  const body = await response.json();
  const documents: PoiDocument[] = body.data;
  latestTimestamp =
    documents
      .map((document) => new Date(document?._updatedBy.timestamp).getTime())
      .sort((a, b) => b - a)
      .shift() || 0;
}

registerRoute(
  config.dataUrl,
  new StaleWhileRevalidate({
    plugins: [
      {
        requestWillFetch: async () => {
          return new Request(
            `${config.dataUrl}/updateAvailable?timestamp=${latestTimestamp}`
          );
        },
        fetchDidSucceed: async ({ response }) => {
          const updateAvailable = await response.json();

          const cache = await caches.open(cacheNames.runtime);
          const cachedResponse = await cache.match(config.dataUrl);

          if (!updateAvailable && cachedResponse) {
            return cachedResponse;
          }

          const dataResponse = await fetch(config.dataUrl);
          // If new data was fetched, update latest poi timestamp.
          updateLatestTimestamp(dataResponse.clone());
          return dataResponse;
        }
      }
    ]
  })
);

registerRoute(config.localesUrl, new StaleWhileRevalidate({}));
